import { CookieAcceptanceLevel } from "../models/cookieAcceptanceLevelEnum"

export const getCookieAcceptanceLevel = () => {
  const cookieAcceptance = useCookie("cookieAcceptance")

  const cookieAcceptanceString = cookieAcceptance.value
  if (!cookieAcceptanceString) {
    return CookieAcceptanceLevel.None
  }
  const cookieAcceptanceState = cookieAcceptanceString
  if (!cookieAcceptanceState) {
    return CookieAcceptanceLevel.None
  }

  switch (cookieAcceptanceState) {
    case "all":
      return CookieAcceptanceLevel.AllAccepted
    case "necessary":
      return CookieAcceptanceLevel.NecessaryAccepted
    case "statistics":
      return CookieAcceptanceLevel.StatisticsAccepted
    case "video":
      return CookieAcceptanceLevel.VideosAccepted
    case "none":
      return CookieAcceptanceLevel.None
    default:
      return null
  }
}

export const acceptAllCookies = () => {
  const cookieAcceptance = useCookie("cookieAcceptance")
  cookieAcceptance.value = CookieAcceptanceLevel.AllAccepted.name
}
export const acceptNecessaryCookies = () => {
  const cookieAcceptance = useCookie("cookieAcceptance")
  cookieAcceptance.value = CookieAcceptanceLevel.NecessaryAccepted.name
}
export function acceptStatisticsCookies() {
  const cookieAcceptance = useCookie("cookieAcceptance")
  cookieAcceptance.value = CookieAcceptanceLevel.StatisticsAccepted.name
}
export const acceptVideoCookies = () => {
  const cookieAcceptance = useCookie("cookieAcceptance")
  cookieAcceptance.value = CookieAcceptanceLevel.VideosAccepted.name
}

export const wasAnalyticsAccepted = () => {
  const cookieAcceptance = useCookie("cookieAcceptance")
  const cookieAcceptanceString = cookieAcceptance.value
  if (!cookieAcceptanceString) {
    return false
  }

  return cookieAcceptanceString === "all" || cookieAcceptanceString === "statistics"
}
