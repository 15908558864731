class CookieAcceptanceLevel {
  constructor(name) {
    this.name = name
  }
}

CookieAcceptanceLevel.AllAccepted = new CookieAcceptanceLevel("all")
CookieAcceptanceLevel.NecessaryAccepted = new CookieAcceptanceLevel("necessary")
CookieAcceptanceLevel.StatisticsAccepted = new CookieAcceptanceLevel("statistics")
CookieAcceptanceLevel.VideosAccepted = new CookieAcceptanceLevel("video")
CookieAcceptanceLevel.None = new CookieAcceptanceLevel("none")

export { CookieAcceptanceLevel }
